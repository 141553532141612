import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { com } from '@eidu/entity'
import React, { useEffect, useState } from 'react'
import SelectMultipleOptionsInput from '../SelectMultipleOptionsInput'
import FieldValueEqualsForm, { TypeEntitySelectorType } from './FieldValueEqualsForm'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import Permission = com.eidu.sharedlib.entity.permission.Permission
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import NonEmptyList = com.quickbirdstudios.nonEmptyCollection.list.NonEmptyList

type UserEntityTypeDropdownProps = {
  options: readonly EntityType[]
  selected: EntitySelector
  setSelected: (selector: EntitySelector) => void
}

const UserEntityTypeDropdown = ({ options, selected, setSelected }: UserEntityTypeDropdownProps) => (
  <FormControl>
    <InputLabel id="permission-grant-user-entity-type-label">User type</InputLabel>
    <Select
      labelId="permission-grant-user-entity-type-label"
      value={selected instanceof EntitySelector.OfType ? selected.typeId.asString() : 'All'}
      label="User type"
      onChange={(event) => {
        if (event.target.value === 'All') {
          setSelected(EntitySelector.All)
        } else {
          setSelected(new EntitySelector.OfType(entityTypeIdFromString(event.target.value)))
        }
      }}
    >
      <MenuItem key="All" value="All">
        <em>All</em>
      </MenuItem>
      {options.map(({ id, name }) => (
        <MenuItem key={id.asString()} value={id.asString()}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

type PermissionGrantFormProps = {
  userEntityTypes: readonly EntityType[]
  userSelector: EntitySelector
  setUserSelector: (selector: EntitySelector) => void
  permissionOptions: readonly (readonly [Permission, string])[]
  selectedPermissions: readonly Permission[]
  setSelectedPermissions: (permissions: readonly Permission[]) => void
}

const PermissionGrantForm = ({
  userEntityTypes,
  userSelector,
  setUserSelector,
  permissionOptions,
  selectedPermissions,
  setSelectedPermissions,
}: PermissionGrantFormProps) => {
  const [localUserSelector, setLocalUserSelector] = useState<EntitySelector>(EntitySelector.All)
  const [typeEntitySelectorType, setTypeEntitySelectorType] = useState<TypeEntitySelectorType>()
  const [localFieldValueEquals, setLocalFieldValueEquals] = useState<EntitySelector.FieldValueEquals>()

  useEffect(() => {
    if (userSelector instanceof EntitySelector.And) {
      const selectors = userSelector.selectors.asJsReadonlyArrayView()
      const ofTypeSelector = selectors.find((selector) => selector instanceof EntitySelector.OfType)
      const fieldValueEqualsSelector = selectors.find((selector) => selector instanceof EntitySelector.FieldValueEquals)
      if (ofTypeSelector && fieldValueEqualsSelector) {
        setTypeEntitySelectorType(TypeEntitySelectorType.FieldValueEquals)
        setLocalUserSelector(ofTypeSelector)
        setLocalFieldValueEquals(fieldValueEqualsSelector)
      } else {
        throw new Error('Invalid user selector')
      }
    } else {
      setTypeEntitySelectorType(TypeEntitySelectorType.AllOfType)
      setLocalUserSelector(userSelector)
      setLocalFieldValueEquals(undefined)
    }
  }, [userSelector])

  return (
    <Stack padding={0} spacing={3}>
      <Typography variant="h5">Users</Typography>
      {localUserSelector && (
        <UserEntityTypeDropdown
          options={userEntityTypes}
          selected={localUserSelector}
          setSelected={(selector) => {
            setUserSelector(selector)
          }}
        />
      )}
      {localUserSelector instanceof EntitySelector.OfType && typeEntitySelectorType !== undefined && (
        <FieldValueEqualsForm
          entityTypeId={localUserSelector.typeId}
          type={typeEntitySelectorType}
          setType={setTypeEntitySelectorType}
          selector={localFieldValueEquals}
          setSelector={(newFieldValueEquals) => {
            if (newFieldValueEquals)
              setUserSelector(
                new EntitySelector.And(
                  NonEmptyList.fromArray([new EntitySelector.OfType(localUserSelector.typeId), newFieldValueEquals])
                )
              )
          }}
        />
      )}
      <Typography variant="h5">Actions</Typography>
      <SelectMultipleOptionsInput
        options={permissionOptions.map(([permission, label]) => ({ [label]: permission }))}
        selected={selectedPermissions}
        onSelectedChange={setSelectedPermissions}
      />
    </Stack>
  )
}

export default PermissionGrantForm
