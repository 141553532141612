import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce'
import * as Sentry from '@sentry/react'
import App from './App'
import ErrorPage from './pages/ErrorPage'
import FormConfigurationsPage from './pages/form/FormConfigurationsPage'
import FormConfigurationPage from './pages/form/FormConfigurationPage'
import ListPage from './pages/entity/ListPage'
import ExportPage from './pages/entity/ExportPage'
import './util/scope'
import EntityTypesPage from './pages/entity/EntityTypesPage'
import { PROD } from './config/stage'
import ImportPageRoute from './pages/entity/routes/ImportPageRoute'
import { ConfigurePageRoute } from './pages/entity/routes/ConfigurePageRoute'
import CreateOrEditPage from './pages/entity/CreateOrEditPage'
import PermissionGrantsListPageRoute from './pages/permission/PermissionGrantsListPage'
import CreateOrEditPermissionGrantPageRoute from './pages/permission/CreateOrEditPermissionGrantPage'

const stage = import.meta.env.VITE_STAGE
const dsn = 'https://8dc821e5d3594e9c4eaabfe634b1dcf1@o232550.ingest.us.sentry.io/4508138519134208'

const logToSentry = import.meta.env.MODE === 'production'

Sentry.init({
  dsn: logToSentry ? dsn : '',
  integrations: [],
  environment: stage,
})

const authServiceStagePrefix = stage === PROD ? '' : 'test-'
const authServiceHost = `${authServiceStagePrefix}auth.eidu.com`

const authConfig: TAuthConfig = {
  clientId: 'eidu-console',
  authorizationEndpoint: `https://${authServiceHost}/realms/eidu/protocol/openid-connect/auth`,
  tokenEndpoint: `https://${authServiceHost}/realms/eidu/protocol/openid-connect/token`,
  redirectUri: window.location.origin,
  logoutEndpoint: `https://${authServiceHost}/realms/eidu/protocol/openid-connect/logout`,
  logoutRedirect: window.location.origin,
  scope: 'openid profile',
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.logIn(),
}

// Hack to fix weird behaviour of react-oauth2-code-pkce
if (!window.location.search.includes('code=')) localStorage.setItem('ROCP_loginInProgress', 'false')

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'forms',
        element: <FormConfigurationsPage />,
        children: [
          {
            path: ':formId',
            element: <FormConfigurationPage />,
          },
          {
            path: 'new',
            element: <FormConfigurationPage />,
          },
        ],
      },
      {
        path: 'entity-types',
        element: <EntityTypesPage />,
      },
      {
        path: 'entities/:entityTypeId',
        element: <ListPage />,
      },
      {
        path: 'entities/:entityTypeId/import',
        element: <ImportPageRoute />,
      },
      {
        path: 'entities/:entityTypeId/configure',
        element: <ConfigurePageRoute />,
      },
      {
        path: 'entities/:entityTypeId/new',
        element: <CreateOrEditPage />,
      },
      {
        path: 'entities/:entityTypeId/edit/:entityId',
        element: <CreateOrEditPage />,
      },
      {
        path: 'entities/new',
        element: <ConfigurePageRoute />,
      },
      {
        path: 'export',
        element: <ExportPage />,
      },
      {
        path: 'access-control',
        element: <PermissionGrantsListPageRoute />,
      },
      {
        path: 'access-control/new',
        element: <CreateOrEditPermissionGrantPageRoute isEditMode={false} />,
      },
      {
        path: 'access-control/:permissionGrantId',
        element: <CreateOrEditPermissionGrantPageRoute isEditMode />,
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig}>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
)
