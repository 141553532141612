import { IAuthContext } from 'react-oauth2-code-pkce'

type AuthenticationContext = {
  accessToken: string
  organizationId: string
  userEntityId: string
}

export const createAuthContext = (context: IAuthContext): AuthenticationContext | undefined =>
  context.idTokenData?.organization_id &&
  context.idTokenData?.user_entity_id && {
    accessToken: context.token,
    organizationId: context.idTokenData.organization_id,
    userEntityId: context.idTokenData.user_entity_id,
  }

export default AuthenticationContext
