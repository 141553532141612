import { com } from '@eidu/entity'
import { useEffect, useMemo, useState } from 'react'
import EntityRepository, { updateAccessToken } from './EntityRepository'
import EqualityHashMap from '../util/EqualityHashMap'
import EntityWithLabelAndRelated from '../domain/entity/EntityWithLabelAndRelated'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType

const DEFAULT_PAGE_SIZE = 100

type UseEntitiesOfTypeParams = {
  typeIds: readonly EntityTypeId[]
  searchQuery?: string
  allTypes: ReadonlyMap<EntityTypeId, EntityType>
  pageSize?: number
}

/**
 * @returns a map of type IDs to a map of entity IDs to entities
 */
const useEntitiesOfType = (
  { typeIds, searchQuery, allTypes, pageSize = DEFAULT_PAGE_SIZE }: UseEntitiesOfTypeParams,
  deps: unknown[] = []
): ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>> | undefined => {
  updateAccessToken()
  const repos = useMemo(
    () =>
      new EqualityHashMap(
        typeIds.map((typeId) => [typeId, new EntityRepository({ typeId, searchQuery, types: allTypes, pageSize })])
      ),
    [
      JSON.stringify(typeIds.map((it) => it.asString())),
      JSON.stringify([...allTypes.keys()].map((it) => it.asString())),
      pageSize,
      ...deps,
    ]
  )

  const [value, setValue] = useState<
    ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>> | undefined
  >()
  useEffect(() => {
    Promise.all([...repos.entries()].map(([typeId, repo]) => repo.getAll().then((it) => [typeId, it] as const))).then(
      (it) =>
        setValue(
          new EqualityHashMap(
            it.map(([typeId, entitiesById]) => [
              typeId,
              new EqualityHashMap(
                [...entitiesById.entries()].flatMap(([entityId, entity]) => (entity ? [[entityId, entity]] : []))
              ),
            ])
          )
        )
    )
  }, [repos])

  return value
}

export default useEntitiesOfType
