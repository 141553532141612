import { com, kotlin } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import requireValidPaginationParameters from '../common/requireValidPaginationParameters'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import permissionGrantDTO, { PermissionGrantDTO } from '../../../domain/permission/PermissionGrantDTO'
import PaginatedResponse = com.eidu.api.PaginatedResponse
import KtList = kotlin.collections.KtList

export type GetPermissionGrantsParameters = { pageIndex: number; pageSize: number; authContext: AuthenticationContext }

const getPermissionGrants = async ({
  pageIndex,
  pageSize,
  authContext,
}: GetPermissionGrantsParameters): Promise<PaginatedResponse<PermissionGrantDTO>> => {
  requireValidPaginationParameters(pageIndex, pageSize)

  const response = await fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
    }
  )
  if (response.status !== 200)
    throw Error(`Unexpected response code ${response.status} from getPermissionGrants endpoint`)
  const { documents, totalCount } = await response.json()
  const permissionGrants = documents.map(permissionGrantDTO)
  return new PaginatedResponse(KtList.fromJsArray(permissionGrants), totalCount)
}

export default getPermissionGrants
