import { IconButton, ListItemText, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'

export type SelectOptionInputProps<T> = {
  availableOptions: readonly { [label: string]: T }[]
  selected: T
  setSelected: (selected: T) => void
  disabled?: boolean
}

const getItemLabel = <T extends unknown>(item: { [label: string]: T }) => Object.keys(item)[0]
const getItemValue = <T extends unknown>(item: { [label: string]: T }) => item[Object.keys(item)[0]]

const SelectOptionInput = <T extends unknown>({
  availableOptions,
  selected,
  setSelected,
  disabled,
}: SelectOptionInputProps<T>) => (
  <Select
    value={availableOptions.findIndex((item) => getItemValue(item) === selected)}
    disabled={disabled}
    onChange={(event: SelectChangeEvent<number>) => {
      const newSelectedIndex = event.target.value as number
      setSelected(getItemValue(availableOptions[newSelectedIndex]))
    }}
  >
    {availableOptions.map((value, index) => (
      <MenuItem key={JSON.stringify(value)} value={index}>
        <ListItemText primary={getItemLabel(value)} />
      </MenuItem>
    ))}
  </Select>
)

export type OptionInputProps<T> = {
  options: readonly { [label: string]: T }[]
  selectedOption: T | undefined
  onAdd: () => void
  onChange: (updatedOption: T) => void
  onDelete: () => void
}

const OptionInput = <T extends unknown>({
  options,
  selectedOption,
  onAdd,
  onChange,
  onDelete,
}: OptionInputProps<T>) => (
  <Stack>
    <Stack direction="row" spacing={1}>
      <SelectOptionInput
        availableOptions={options}
        selected={selectedOption}
        setSelected={(newValue) => {
          if (newValue) onChange(newValue)
        }}
      />
      <IconButton onClick={onDelete}>
        <Delete color="error" />
      </IconButton>
      <IconButton onClick={onAdd}>
        <Add color="primary" />
      </IconButton>
    </Stack>
  </Stack>
)

export type ObjectListProps<T> = {
  options: readonly { [label: string]: T }[]
  selected: readonly T[]
  onSelectedChange: (T: readonly T[]) => void
}

const SelectMultipleOptionsInput = <T extends unknown>({ options, selected, onSelectedChange }: ObjectListProps<T>) => {
  const defaultOption = getItemValue(options[0])
  return (
    <Stack direction="column" alignItems="flex-start" spacing="16px">
      {selected.length === 0 && (
        <IconButton onClick={() => onSelectedChange(selected.toSpliced(0, 0, defaultOption))}>
          <Add color="primary" />
        </IconButton>
      )}
      {[...selected].map((option, index) => {
        const key = JSON.stringify(option) + index
        return (
          <OptionInput
            key={key}
            options={options}
            selectedOption={option}
            onAdd={() => onSelectedChange(selected.toSpliced(index + 1, 0, defaultOption))}
            onChange={(newValue) => onSelectedChange(selected.toSpliced(index, 1, newValue))}
            onDelete={() => onSelectedChange(selected.toSpliced(index, 1))}
          />
        )
      })}
    </Stack>
  )
}

export default SelectMultipleOptionsInput
