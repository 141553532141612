import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import permissionGrantDTO, { PermissionGrantDTO } from '../../../domain/permission/PermissionGrantDTO'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import Permission = com.eidu.sharedlib.entity.permission.Permission
import CreatePermissionGrantRequest = com.eidu.sharedlib.entity.api.permissions.CreatePermissionGrantRequest
import PermissionGrant = com.eidu.sharedlib.entity.permission.PermissionGrant
import NonEmptySet = com.quickbirdstudios.nonEmptyCollection.set.NonEmptySet
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector

export type CreatePermissionGrantParameters = {
  userSelector: EntitySelector
  permissions: readonly Permission[]
  authContext: AuthenticationContext
}

const createPermissionGrant = ({
  userSelector,
  permissions,
  authContext,
}: CreatePermissionGrantParameters): Promise<PermissionGrantDTO> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new CreatePermissionGrantRequest(new PermissionGrant(userSelector, NonEmptySet.fromArray([...permissions])))
    ),
    method: 'POST',
  }).then(async (response) => {
    if (response.status !== 201)
      throw Error(`Unexpected response code ${response.status} from createPermissionGrant endpoint`)
    return permissionGrantDTO((await response.json()).document)
  })

export default createPermissionGrant
