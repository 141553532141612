import {
  ColumnMenuPropsOverrides,
  DataGrid,
  DataGridProps,
  GridColumnMenuProps,
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid'
import { TablePaginationProps } from '@mui/material'
import React, { JSXElementConstructor } from 'react'
import MuiPagination from '@mui/material/Pagination'
import { getPageCount } from '@mui/x-data-grid/hooks/features/pagination/gridPaginationUtils'
import pageSizeOptions from '../util/pagination/pageSizeOptions'

const Pagination = ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector)
  const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize, page)

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as React.MouseEvent<HTMLButtonElement>, newPage - 1)
      }}
    />
  )
}

const DataGridWithPageSelection = ({
  rows,
  columns,
  checkboxSelection,
  paginationMode,
  rowCount,
  paginationModel,
  onPaginationModelChange,
  loading,
  apiRef,
  onRowClick,
  rowSelectionModel,
  onRowSelectionModelChange,
  customColumnMenu,
  columnMenuProps,
}: Pick<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'checkboxSelection'
  | 'paginationMode'
  | 'rowCount'
  | 'paginationModel'
  | 'onPaginationModelChange'
  | 'loading'
  | 'apiRef'
  | 'onRowClick'
  | 'rowSelectionModel'
  | 'onRowSelectionModelChange'
> & {
  customColumnMenu?: JSXElementConstructor<GridColumnMenuProps & ColumnMenuPropsOverrides>
  columnMenuProps?: Partial<GridColumnMenuProps & ColumnMenuPropsOverrides>
}) => (
  <DataGrid
    autosizeOnMount
    autoHeight
    rows={rows}
    columns={columns.map((column) => ({ ...column, flex: 1, minWidth: 50 }))}
    checkboxSelection={checkboxSelection}
    disableColumnFilter
    disableColumnSelector
    slots={customColumnMenu && { columnMenu: customColumnMenu }}
    rowSelectionModel={rowSelectionModel}
    onRowSelectionModelChange={onRowSelectionModelChange}
    pagination
    slotProps={{
      pagination: { ActionsComponent: Pagination },
      columnMenu: columnMenuProps,
    }}
    paginationModel={paginationModel}
    onPaginationModelChange={onPaginationModelChange}
    pageSizeOptions={Object.values(pageSizeOptions)}
    paginationMode={paginationMode}
    rowCount={rowCount}
    disableColumnMenu={!customColumnMenu}
    disableRowSelectionOnClick
    keepNonExistentRowsSelected
    loading={loading}
    apiRef={apiRef}
    onRowClick={(params, event, details) => {
      if (onRowClick) onRowClick(params, event, details)
    }}
    sx={
      onRowClick && {
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }
    }
  />
)

export default DataGridWithPageSelection
