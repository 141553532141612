import { Stack, Typography } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'

type FormDefinitionLanguageDocumentationLinkProps = {
  yaml: boolean
}

const FormDefinitionLanguageDocumentationLink = ({ yaml }: FormDefinitionLanguageDocumentationLinkProps) =>
  yaml ? (
    <a
      href="https://docs.google.com/document/d/1joS4EF6RcyEcPYX7m73irtBQ6d0okUV7sJIEWxALl8Y/edit?tab=t.0#heading=h.mhy9pgbgj7c3"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Stack direction="row" alignItems="center" spacing="4px">
        <Typography>View documentation on the form definition language</Typography>
        <OpenInNew fontSize="small" />
      </Stack>
    </a>
  ) : (
    <a
      href="https://docs.google.com/document/d/1tjoP6oc3HBOAbbWXqwS_v-A_VbNoqwvkm2BOrkXoh2E/edit#heading=h.kk1966kbedef"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Stack direction="row" alignItems="center" spacing="4px">
        <Typography>View documentation on the form definition language</Typography>
        <OpenInNew fontSize="small" />
      </Stack>
    </a>
  )

export default FormDefinitionLanguageDocumentationLink
