import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import UserPermissionsResponse = com.eidu.sharedlib.entity.api.permissions.UserPermissionsResponse

export type GetUserPermissionsParameters = { authContext: AuthenticationContext }

const getUserPermissions = ({ authContext }: GetUserPermissionsParameters): Promise<UserPermissionsResponse> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/user/${authContext.userEntityId}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from getUserPermissions endpoint`)
    return UserPermissionsResponse.fromJsonString(await response.text())
  })

export default getUserPermissions
