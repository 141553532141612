import { useContext, useEffect, useMemo } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import PermissionGrantRepository, { setGlobalAuthContext } from './PermissionGrantRepository'

const usePermissionGrantRepository = (pageSize: number = 10, deps?: readonly unknown[]): PermissionGrantRepository => {
  const authContext = useContext<IAuthContext>(AuthContext)
  useEffect(() => {
    setGlobalAuthContext(authContext)
  }, [authContext])

  return useMemo(() => new PermissionGrantRepository({ pageSize }), [pageSize, ...(deps ?? [])])
}

export default usePermissionGrantRepository
