import { useEffect, useState } from 'react'
import BatchCache from './BatchCache'
import range from '../util/range'
import PeekablePromise from '../util/PeekablePromise'
import EqualityHashMap, { EqualityHashMapKey } from '../util/EqualityHashMap'

export type Page<K, V> = {
  entries: ReadonlyMap<K, V>
  totalCount: number
}

class PageCache<K extends EqualityHashMapKey, V> {
  private readonly cache: BatchCache<K, V>

  private readonly pageCache = new Map<number, PeekablePromise<K[]>>()

  private numItems: number | null = null

  private readonly fetchPage: (pageIndex: number, pageSize: number) => Promise<Page<K, V>>

  private readonly pageSize: number

  private allPromise: Promise<ReadonlyMap<K, V>> | null = null

  constructor(
    cache: BatchCache<K, V>,
    fetchPage: (pageIndex: number, pageSize: number) => Promise<Page<K, V>>,
    pageSize: number
  ) {
    this.cache = cache
    this.fetchPage = fetchPage
    this.pageSize = pageSize
  }

  peekPage(pageIndex: number): ReadonlyMap<K, V> {
    const cachedKeys = this.pageCache.get(pageIndex)?.peek()
    if (cachedKeys !== undefined) {
      return this.cache.peekBatch(cachedKeys)
    } else {
      return new Map()
    }
  }

  get = async (id: K) => this.cache.get(id)

  async getPage(pageIndex: number): Promise<ReadonlyMap<K, V>> {
    const cachedKeysPromise = this.pageCache.get(pageIndex)
    if (cachedKeysPromise !== undefined) {
      const cachedKeys = await cachedKeysPromise.get()
      if (cachedKeys !== undefined) {
        const cached = this.cache.peekBatch(cachedKeys)
        if (cached.size === cachedKeys.length) {
          return cached
        }
      }
    }

    try {
      const fetchPromise = this.loadPage(pageIndex, this.pageSize)
      this.pageCache.set(
        pageIndex,
        PeekablePromise.create(fetchPromise.then((page) => Array.from(page.entries.keys())).catch())
      )

      const fetched = await fetchPromise
      this.numItems = fetched.totalCount
      await this.cache.setBatch(fetched.entries).catch(() => undefined)
      return fetched.entries
    } catch (error) {
      await this.cache.handleError(error)
      throw error
    }
  }

  private async loadPage(pageIndex: number, pageSize: number): Promise<Page<K, V>> {
    await this.cache.beginLoading()
    try {
      return await this.fetchPage(pageIndex, pageSize)
    } finally {
      await this.cache.endLoading()
    }
  }

  async getNumItems(): Promise<number> {
    if (this.numItems !== null) {
      return this.numItems
    } else {
      await this.getPage(0)
      return this.numItems || -1
    }
  }

  async getAll(): Promise<ReadonlyMap<K, V>> {
    if (this.allPromise != null) return this.allPromise
    else return this.refreshAll()
  }

  refreshAll = (): Promise<ReadonlyMap<K, V>> =>
    this.doRefreshAll().also((it) => {
      this.allPromise = it
    })

  private async doRefreshAll(): Promise<ReadonlyMap<K, V>> {
    try {
      const firstPagePromise = this.loadPage(0, this.pageSize)
      this.cache.invalidateAll()
      this.pageCache.clear()
      this.setPagePromise(0, firstPagePromise)
      const firstPage = await firstPagePromise

      this.numItems = firstPage.totalCount

      const numPages = Math.ceil(firstPage.totalCount / this.pageSize)
      const otherPagePromises = range(1, numPages).map((pageIndex) => this.loadPage(pageIndex, this.pageSize))
      otherPagePromises.forEach((promise, index) => this.setPagePromise(index + 1, promise))
      const otherPages = await Promise.all(otherPagePromises)

      const pages = [firstPage, ...otherPages]

      return new EqualityHashMap(pages.flatMap((page) => Array.from(page.entries.entries())))
    } catch (error) {
      await this.cache.handleError(error)
      throw error
    }
  }

  private setPagePromise(pageIndex: number, promise: Promise<Page<K, V>>) {
    this.pageCache.set(pageIndex, PeekablePromise.create(promise.then((page) => Array.from(page.entries.keys()))))
    promise.then((page) => this.cache.setBatch(page.entries)).catch()
  }

  useItem = (key: K, deps: readonly unknown[] = []) => this.cache.useItem(key, deps)

  useBatch = (keys: K[], deps: readonly unknown[] = []) => this.cache.useBatch(keys, deps)

  usePage(pageIndex: number, deps: readonly unknown[] = []) {
    const [value, setValue] = useState(this.peekPage(pageIndex))

    useEffect(() => {
      this.getPage(pageIndex).then(setValue)
      const listener = async (update: ReadonlyMap<K, V>) => {
        const pageKeys = await this.pageCache.get(pageIndex)?.get()
        if (Array.from(update.keys()).some((key) => pageKeys?.some((it) => key.equals(it))))
          setValue(await this.getPage(pageIndex))
      }
      this.cache.addListener(listener)
      return () => this.cache.removeListener(listener)
    }, [pageIndex, ...deps])

    return value
  }

  useNumItems(deps: readonly unknown[] = []) {
    const [value, setValue] = useState(this.numItems)

    useEffect(() => {
      this.getNumItems().then(setValue)
      const listener = async () => {
        setValue(await this.getNumItems())
      }

      this.cache.addListener(listener)
      return () => this.cache.removeListener(listener)
    }, deps)

    return value
  }

  useAll() {
    const [value, setValue] = useState<ReadonlyMap<K, V> | undefined>(undefined)

    useEffect(() => {
      let hasAll = false
      this.getAll().then((it) => {
        setValue(it)
        hasAll = true
      })

      const listener = async () => {
        if (hasAll) setValue(this.cache.peekAll())
      }
      this.cache.addListener(listener)
      return () => this.cache.removeListener(listener)
    }, [])

    return value
  }

  clearError = () => this.cache.clearError()

  useError = () => this.cache.useError()

  useLoading = () => this.cache.useLoading()
}

export default PageCache
