import React, { useEffect, useState } from 'react'
import LoadingState from '../util/LoadingState'
import ErrorState from '../util/ErrorState'
import PeekablePromise from '../util/PeekablePromise'
import EqualityHashMap, { EqualityHashMapKey } from '../util/EqualityHashMap'

type KeyListener<K, V> = {
  key: K
  notify: React.Dispatch<React.SetStateAction<V | undefined>>
}

export type Listener<K extends EqualityHashMapKey, V> = (update: ReadonlyMap<K, V>) => Promise<void>

class BatchCache<K extends EqualityHashMapKey, V> {
  private readonly cache = new EqualityHashMap<K, PeekablePromise<V>>()

  private readonly fetch: (key: K) => Promise<V>

  private readonly fetchBatch: (keys: readonly K[]) => Promise<ReadonlyMap<K, V>>

  private keyListeners: KeyListener<K, V>[] = []

  private listeners: Listener<K, V>[] = []

  private errorState = new ErrorState()

  private loadingState = new LoadingState()

  constructor(fetch: (key: K) => Promise<V>, fetchBatch?: (keys: readonly K[]) => Promise<ReadonlyMap<K, V>>) {
    this.fetch = fetch
    this.fetchBatch = fetchBatch || this.fetchBatchFallback.bind(this)
  }

  private fetchBatchFallback = async (keys: readonly K[]): Promise<ReadonlyMap<K, V>> =>
    Promise.all(keys.map((key) => this.fetch(key).then((value) => [key, value] as [K, V]))).then(
      (it) => new EqualityHashMap(it)
    )

  peek = (key: K): V | undefined => this.cache.get(key)?.peek()

  peekBatch(keys: readonly K[]): ReadonlyMap<K, V> {
    return new EqualityHashMap(
      keys
        .map((key): [K, V] | undefined => {
          const cached = this.cache.get(key)?.peek()
          if (cached !== undefined) return [key, cached]
          else return undefined
        })
        .filter((it): it is [K, V] => it !== undefined)
    )
  }

  peekAll(): ReadonlyMap<K, V> {
    return new EqualityHashMap(
      Array.from(this.cache.entries())
        .map(([key, value]) => [key, value.peek()])
        .filter((it): it is [K, V] => it[1] !== undefined)
    )
  }

  set = (key: K, value: V): Promise<void> => this.setPromise(key, Promise.resolve(value))

  setBatch = (entries: ReadonlyMap<K, V>): Promise<void> =>
    this.setPromiseBatch(Array.from(entries.keys()), Promise.resolve(entries))

  private setPromise(key: K, promise: Promise<V>) {
    this.cache.set(key, PeekablePromise.create(promise))
    return promise.then((value) => this.notifyListenersSingle(key, value))
  }

  private setPromiseBatch(keys: readonly K[], promise: Promise<ReadonlyMap<K, V>>) {
    PeekablePromise.createFromMap(promise, keys).forEach((peekable, key) => {
      if (!this.cache.has(key)) this.cache.set(key, peekable)
    })
    return promise.then((values) => this.notifyListenersMulti(values))
  }

  get(key: K): Promise<V> {
    const cached = this.cache.get(key)
    if (cached !== undefined) {
      return cached.get()
    } else {
      const promise = this.load(key)
      promise.catch(this.handleError.bind(this))
      this.setPromise(key, promise).catch(() => undefined)
      return promise
    }
  }

  async getBatch(keys: readonly K[]): Promise<ReadonlyMap<K, V>> {
    const found = new EqualityHashMap<K, Promise<[K, V]>>()
    keys.forEach((key) => {
      const peekable = this.cache.get(key)
      if (peekable !== undefined) {
        found.set(
          key,
          peekable.get().then((it) => [key, it])
        )
      }
    })

    const uncachedKeys = keys.filter((key) => !found.has(key))

    try {
      const loadPromise: Promise<ReadonlyMap<K, V>> =
        uncachedKeys.length > 0 ? this.loadBatch(uncachedKeys) : Promise.resolve(new Map())
      if (uncachedKeys.length > 0) this.setPromiseBatch(keys, loadPromise).catch(() => undefined)

      return (
        await Promise.all([Promise.all(found.values()).then((it) => new EqualityHashMap(it)), loadPromise])
      ).reduce((acc: EqualityHashMap<K, V>, map: ReadonlyMap<K, V>): EqualityHashMap<K, V> => {
        map.forEach((value: V, key: K) => {
          if (keys.some((it) => it.equals(key))) acc.set(key, value)
        })
        return acc
      }, new EqualityHashMap<K, V>())
    } catch (error) {
      await this.handleError(error)
      throw error
    }
  }

  invalidate(key: K) {
    this.cache.delete(key)
  }

  invalidateBatch(keys: readonly K[]) {
    keys.forEach((key) => {
      this.cache.delete(key)
    })
  }

  invalidateAll() {
    this.cache.clear()
  }

  async refresh(key: K) {
    this.invalidate(key)
    return this.get(key)
  }

  async refreshBatch(keys: readonly K[]) {
    this.invalidateBatch(keys)
    return this.getBatch(keys)
  }

  refreshAll = () => this.refreshBatch(Array.from(this.cache.keys()))

  useItem(key: K, deps: readonly unknown[] = []) {
    const [value, setValue] = useState(this.cache.get(key)?.peek())
    useEffect(() => {
      this.get(key)
        .then(setValue)
        .catch(() => undefined)
      this.addKeyListener(key, setValue)
      return () => this.removeKeyListener(key, setValue)
    }, [key, ...deps])
    return value
  }

  useBatch(keys: readonly K[], deps: readonly unknown[] = []) {
    const [value, setValue] = useState(this.peekBatch(keys))

    useEffect(() => {
      this.getBatch(keys).then(setValue)
      const listener = async (update: ReadonlyMap<K, V>) => {
        if (Array.from(update.keys()).some((key) => keys.some((it) => key.equals(it))))
          setValue(await this.getBatch(keys))
      }
      this.addListener(listener)
      return () => this.removeListener(listener)
    }, [JSON.stringify(keys.toSorted()), ...deps])

    return value
  }

  addListener(listener: Listener<K, V>) {
    this.listeners.push(listener)
  }

  removeListener(listener: Listener<K, V>) {
    this.listeners = this.listeners.filter((it) => it !== listener)
  }

  addKeyListener(key: K, notify: React.Dispatch<React.SetStateAction<V | undefined>>) {
    this.keyListeners.push({ key, notify })
  }

  removeKeyListener(key: K, notify: React.Dispatch<React.SetStateAction<V | undefined>>) {
    this.keyListeners = this.keyListeners.filter((listener) => !listener.key.equals(key) || listener.notify !== notify)
  }

  private async notifyListenersSingle(key: K, value: V) {
    this.keyListeners.forEach((listener) => {
      if (listener.key.equals(key)) listener.notify(value)
    })
    const update = new EqualityHashMap([[key, value]])
    await Promise.all(this.listeners.map((listener) => listener(update)))
  }

  private async notifyListenersMulti(update: ReadonlyMap<K, V>) {
    const eqUpdate = new EqualityHashMap<K, V>(update)
    this.keyListeners.forEach(({ key, notify }) => {
      if (eqUpdate.has(key)) notify(eqUpdate.get(key))
    })
    await Promise.all(this.listeners.map((listener) => listener(eqUpdate)))
  }

  handleError = (error: unknown) => this.errorState.handleError(error)

  clearError = () => this.errorState.clearError()

  useError = (deps: readonly unknown[] = []) => this.errorState.useError(deps)

  isLoading = () => this.loadingState.isLoading()

  beginLoading = () => this.loadingState.beginLoading()

  endLoading = () => this.loadingState.endLoading()

  useLoading = (deps: readonly unknown[] = []) => this.loadingState.useLoading(deps)

  private async load(key: K) {
    await this.beginLoading()
    try {
      return await this.fetch(key)
    } finally {
      await this.endLoading()
    }
  }

  private async loadBatch(keys: readonly K[]) {
    await this.beginLoading()
    try {
      return await this.fetchBatch(keys)
    } finally {
      await this.endLoading()
    }
  }
}

export default BatchCache
