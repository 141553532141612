import { useContext, useEffect, useState } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { com } from '@eidu/entity'
import AuthenticationContext, { createAuthContext } from '../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../util/require'
import getUserPermissions from '../api/entity/permission/getUserPermissions'
import Permission = com.eidu.sharedlib.entity.permission.Permission

let globalAuthContext: AuthenticationContext | undefined

export function setGlobalAuthContext(authContext: IAuthContext) {
  globalAuthContext = createAuthContext(authContext)
}

const usePermissions = (
  deps?: readonly unknown[]
): [readonly Permission[] | undefined, string | undefined, boolean] => {
  const [permissions, setPermissions] = useState<readonly Permission[]>()
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const authContext = useContext<IAuthContext>(AuthContext)

  useEffect(() => {
    setGlobalAuthContext(authContext)
  }, [authContext])
  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true)
      const authCtx = requireNotUndefinedOrNull(globalAuthContext)
      const userPermissions = await getUserPermissions({ authContext: authCtx })
      setPermissions(userPermissions.permissions.asJsReadonlyArrayView())
    }
    fetchPermissions()
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [authContext, ...(deps ?? [])])

  return [permissions, error, loading]
}

export default usePermissions
