import { Alert, Box, Button, Collapse, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useContext, useEffect, useState } from 'react'
import { com } from '@eidu/entity'
import LoadingOverlay from '../../components/LoadingOverlay'
import PermissionGrantForm from '../../components/permission/PermissionGrantForm'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import { logException } from '../../util/Logging'
import { useSnackBar } from '../../components/SnackBarProvider'
import usePermissionGrantRepository from '../../io/usePermissionGrantRepository'
import { requireNotUndefinedOrNull } from '../../util/require'
import PermissionGrantRepository from '../../io/PermissionGrantRepository'
import Permission = com.eidu.sharedlib.entity.permission.Permission
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import ManageEntityTypes = com.eidu.sharedlib.entity.permission.ManageEntityTypes
import permissionGrantIdFromString = com.eidu.sharedlib.entity.permission.permissionGrantIdFromString
import ManageAuthentication = com.eidu.sharedlib.entity.permission.ManageAuthentication
import ManagePermissionGrants = com.eidu.sharedlib.entity.permission.ManagePermissionGrants
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector

type CreateOrEditPermissionGrantPageContentProps = {
  userEntityTypes: readonly EntityType[]
  userSelector: EntitySelector
  setUserSelector: (id: EntitySelector) => void
  selectedPermissions: readonly Permission[]
  setSelectedPermissions: (permissions: readonly Permission[]) => void
  onSubmit: () => Promise<void>
  onDelete?: () => Promise<void>
  onCancel: () => void
  isEditMode: boolean
}

const CreateOrEditPermissionGrantPageContent = ({
  userEntityTypes,
  userSelector,
  setUserSelector,
  selectedPermissions,
  setSelectedPermissions,
  onSubmit,
  onDelete,
  onCancel,
  isEditMode,
}: CreateOrEditPermissionGrantPageContentProps) => (
  <Stack padding={3}>
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Typography variant="h4">{isEditMode ? 'Update permission' : 'Grant permission'}</Typography>
      {isEditMode && onDelete && (
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      )}
    </Stack>
    <Box sx={{ paddingY: 2 }}>
      <PermissionGrantForm
        permissionOptions={[
          [ManageEntityTypes, 'Manage entity types'],
          [ManageAuthentication, 'Manage authentication'],
          [ManagePermissionGrants, 'Manage permission grants'],
        ]}
        userEntityTypes={userEntityTypes}
        userSelector={userSelector}
        setUserSelector={setUserSelector}
        selectedPermissions={selectedPermissions}
        setSelectedPermissions={setSelectedPermissions}
      />
    </Box>
    <Stack direction="row" spacing={2} sx={{ padding: 2, borderRadius: 1, justifyContent: 'flex-end' }}>
      <Button color="primary" variant="contained" onClick={onCancel}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={onSubmit}>
        Save
      </Button>
    </Stack>
  </Stack>
)

type CreateOrEditPermissionGrantPageProps = {
  permissionGrantRepository: PermissionGrantRepository
  userEntityTypes: readonly EntityType[]
  isEditMode: boolean
}

const CreateOrEditPermissionGrantPage = ({
  permissionGrantRepository,
  userEntityTypes,
  isEditMode,
}: CreateOrEditPermissionGrantPageProps) => {
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const params = useParams()

  const permissionGrantId = isEditMode
    ? permissionGrantIdFromString(requireNotUndefinedOrNull(params.permissionGrantId))
    : undefined
  const [submitting, setSubmitting] = useState(false)

  const permissionGrant = permissionGrantId ? permissionGrantRepository.useItem(permissionGrantId) : undefined
  const [userSelector, setUserSelector] = useState<EntitySelector>(EntitySelector.All)
  const [selectedPermissions, setSelectedPermissions] = useState<readonly Permission[]>([])

  useEffect(() => {
    if (isEditMode && permissionGrant) {
      setUserSelector(permissionGrant.permissionGrant.userSelector)
      setSelectedPermissions([...permissionGrant.permissionGrant.permissions.asJsReadonlySetView()])
    }
  }, [isEditMode, permissionGrant])

  const submit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (userSelector) {
        if (isEditMode && permissionGrantId) {
          await permissionGrantRepository.modifyPermissionGrant(permissionGrantId, userSelector, selectedPermissions)
          showSnackBar(`Updated ${selectedPermissions}`, 'success')
        } else {
          await permissionGrantRepository.createPermissionGrant(userSelector, selectedPermissions)
          showSnackBar(`Granted ${selectedPermissions}`, 'success')
        }
        navigate(-1)
      }
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to ${isEditMode ? 'update' : 'grant'} ${selectedPermissions}`, 'error')
    } finally {
      setSubmitting(false)
    }
  }, [userSelector, selectedPermissions, isEditMode, permissionGrantId])

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onDelete = useCallback(async () => {
    setSubmitting(true)
    try {
      if (isEditMode && permissionGrantId) {
        await permissionGrantRepository.deletePermissionGrant(permissionGrantId)
        showSnackBar(`Deleted permission`, 'success')
        navigate(-1)
      }
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to delete permission`, 'error')
    } finally {
      setSubmitting(false)
    }
  }, [isEditMode, permissionGrantId, permissionGrantRepository])

  const error = permissionGrantRepository.useError()
  const loading = permissionGrantRepository.useLoading()

  return (
    <Stack>
      <Collapse in={!!error}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ margin: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={async () => {
                await permissionGrantRepository.clearError()
                await permissionGrantRepository.refreshAll()
              }}
            >
              Refresh
            </Button>
          }
        >
          <Box>{String(error)}</Box>
          <Box>Please reload the page and try again.</Box>
        </Alert>
      </Collapse>
      {!loading && !error && (
        <CreateOrEditPermissionGrantPageContent
          userEntityTypes={userEntityTypes}
          userSelector={userSelector}
          setUserSelector={setUserSelector}
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
          onSubmit={submit}
          onDelete={isEditMode ? onDelete : undefined}
          onCancel={onCancel}
          isEditMode={isEditMode}
        />
      )}
      <LoadingOverlay isOpen={loading || submitting} />
    </Stack>
  )
}

const CreateOrEditPermissionGrantPageRoute = ({ isEditMode }: { isEditMode: boolean }) => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const userEntityTypes = [...(entityTypeRepository.useAll()?.values() ?? [])].filter(
    (entityType) => entityType.kind === EntityTypeKind.User
  )
  const permissionGrantRepository = usePermissionGrantRepository()

  return (
    <CreateOrEditPermissionGrantPage
      userEntityTypes={userEntityTypes}
      permissionGrantRepository={permissionGrantRepository}
      isEditMode={isEditMode}
    />
  )
}

export { CreateOrEditPermissionGrantPage }
export default CreateOrEditPermissionGrantPageRoute
