import { com } from '@eidu/entity'
import {
  GridComparatorFn,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridValidRowModel,
} from '@mui/x-data-grid'
import React from 'react'
import { Tooltip } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import EntityReference from './EntityReference'
import EntityWithLabel from '../../domain/entity/EntityWithLabel'
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import entityIdFromStringOrNull = com.eidu.sharedlib.entity.entityIdFromStringOrNull

export type CellContent = {
  value: string
  error?: string
}

export const cellContentComparator: GridComparatorFn<CellContent> = (v1, v2, param1, param2) =>
  gridStringOrNumberComparator(v1.value, v2.value, param1, param2)

type RenderCellFunction = (params: GridRenderCellParams<GridValidRowModel, CellContent>) => React.JSX.Element

const getRenderFieldCell = (
  fieldType: FieldType,
  entities: ReadonlyMap<EntityId, EntityWithLabel | null>,
  types: ReadonlyMap<EntityTypeId, EntityType>
): RenderCellFunction => {
  const renderValidationError = (error?: string) =>
    error && (
      <Tooltip title={error}>
        <WarningAmber color="warning" sx={{ margin: 0, paddingX: 1 }} />
      </Tooltip>
    )

  switch (fieldType) {
    case FieldType.Reference:
      return ({ value }) => {
        const fieldValue = value?.value
        const referencedId = fieldValue?.let((it) => entityIdFromStringOrNull(it) ?? undefined)
        const referenced = referencedId?.let((it) => entities.get(it)) ?? undefined
        const type = referenced?.let((it) => types.get(it.entity.typeId))
        const typeName = type?.let((it) => it.name)

        const state = !referenced && referencedId ? 'unresolved' : referencedId ? 'resolved' : 'pending'

        return (
          <>
            {renderValidationError(value?.error)}
            <EntityReference
              label={referenced?.primaryLabelText}
              secondaryLabel={referenced?.secondaryLabelText}
              id={referencedId}
              typeId={referenced?.entity?.typeId}
              typeName={typeName}
              state={state}
            />
          </>
        )
      }

    case FieldType.Text:
    case FieldType.Number:
    default:
      return ({ value }) => (
        <>
          {renderValidationError(value?.error)}
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value?.value || ''}</span>
        </>
      )
  }
}

export default getRenderFieldCell
