import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import permissionGrantDTO, { PermissionGrantDTO } from '../../../domain/permission/PermissionGrantDTO'
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId

export type GetPermissionGrantParameters = { id: PermissionGrantId; authContext: AuthenticationContext }

const getPermissionGrant = ({ id, authContext }: GetPermissionGrantParameters): Promise<PermissionGrantDTO> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from getPermissionGrant endpoint`)
    return permissionGrantDTO((await response.json()).document)
  })

export default getPermissionGrant
