import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId

export type DeletePermissionGrantParameters = {
  id: PermissionGrantId
  authContext: AuthenticationContext
}

const deletePermissionGrant = ({ id, authContext }: DeletePermissionGrantParameters): Promise<void> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    method: 'DELETE',
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from deletePermissionGrant endpoint`)
  })

export default deletePermissionGrant
