import { com } from '@eidu/entity'
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId
import Permission = com.eidu.sharedlib.entity.permission.Permission
import permissionGrantDTO, { PermissionGrantDTO } from '../../../domain/permission/PermissionGrantDTO'
import UpdatePermissionGrantRequest = com.eidu.sharedlib.entity.api.permissions.UpdatePermissionGrantRequest
import PermissionGrant = com.eidu.sharedlib.entity.permission.PermissionGrant
import NonEmptySet = com.quickbirdstudios.nonEmptyCollection.set.NonEmptySet
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector

export type UpdatePermissionGrantParameters = {
  id: PermissionGrantId
  userSelector: EntitySelector
  permissions: readonly Permission[]
  authContext: AuthenticationContext
}

const updatePermissionGrant = ({
  id,
  userSelector,
  permissions,
  authContext,
}: UpdatePermissionGrantParameters): Promise<PermissionGrantDTO> =>
  fetch(`${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/grants/${id.asString()}`, {
    headers: { Authorization: `Bearer ${authContext.accessToken}` },
    body: serializeToJsonString(
      new UpdatePermissionGrantRequest(new PermissionGrant(userSelector, NonEmptySet.fromArray([...permissions])))
    ),
    method: 'PUT',
  }).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from updatePermissionGrant endpoint`)
    return permissionGrantDTO((await response.json()).document)
  })

export default updatePermissionGrant
