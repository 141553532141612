import { com } from '@eidu/entity'
import { IAuthContext } from 'react-oauth2-code-pkce'
import BatchCache from './BatchCache'
import PageCache, { Page } from './PageCache'
import AuthenticationContext, { createAuthContext } from '../api/authorization/AuthenticationContext'
import { requireNotUndefinedOrNull } from '../util/require'
import EqualityHashMap from '../util/EqualityHashMap'
import { PermissionGrantDTO } from '../domain/permission/PermissionGrantDTO'
import PermissionGrantId = com.eidu.sharedlib.entity.permission.PermissionGrantId
import Permission = com.eidu.sharedlib.entity.permission.Permission
import getPermissionGrant from '../api/entity/permission/getPermissionGrant'
import getPermissionGrants from '../api/entity/permission/getPermissionGrants'
import updatePermissionGrant, { UpdatePermissionGrantParameters } from '../api/entity/permission/updatePermissionGrant'
import createPermissionGrant, { CreatePermissionGrantParameters } from '../api/entity/permission/createPermissionGrant'
import deletePermissionGrant from '../api/entity/permission/deletePermissionGrant'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector

let globalAuthContext: AuthenticationContext | undefined

export function setGlobalAuthContext(authContext: IAuthContext) {
  globalAuthContext = createAuthContext(authContext)
}

type PermissionGrantRepositoryParams = {
  pageSize: number
  requestFetchPermissionGrant?: (id: PermissionGrantId) => Promise<PermissionGrantDTO>
  requestFetchPermissionGrantsPage?: (
    pageIndex: number,
    pageSize: number
  ) => Promise<Page<PermissionGrantId, PermissionGrantDTO>>
  requestCreatePermissionGrant?: (params: CreatePermissionGrantParameters) => Promise<PermissionGrantDTO>
  requestModifyPermissionGrant?: (params: UpdatePermissionGrantParameters) => Promise<PermissionGrantDTO>
  requestDeletePermissionGrant?: (id: PermissionGrantId) => Promise<void>
}

class PermissionGrantRepository extends PageCache<PermissionGrantId, PermissionGrantDTO> {
  constructor({
    requestFetchPermissionGrant,
    requestFetchPermissionGrantsPage,
    requestCreatePermissionGrant,
    requestModifyPermissionGrant,
    requestDeletePermissionGrant,
    pageSize,
  }: PermissionGrantRepositoryParams) {
    super(
      new BatchCache(requestFetchPermissionGrant || PermissionGrantRepository.fetchPermissionGrantDefault),
      requestFetchPermissionGrantsPage || PermissionGrantRepository.fetchPermissionGrantsPageDefault,
      pageSize
    )
    this.doCreatePermissionGrant =
      requestCreatePermissionGrant || PermissionGrantRepository.createPermissionGrantDefault
    this.doModifyPermissionGrant =
      requestModifyPermissionGrant || PermissionGrantRepository.modifyPermissionGrantDefault
    this.doDeletePermissionGrant =
      requestDeletePermissionGrant || PermissionGrantRepository.deletePermissionGrantDefault
  }

  private readonly doCreatePermissionGrant: (params: CreatePermissionGrantParameters) => Promise<PermissionGrantDTO>

  private readonly doModifyPermissionGrant: (params: UpdatePermissionGrantParameters) => Promise<PermissionGrantDTO>

  private readonly doDeletePermissionGrant: (id: PermissionGrantId) => Promise<void>

  createPermissionGrant = async (
    userSelector: EntitySelector,
    permissions: readonly Permission[]
  ): Promise<PermissionGrantDTO> => {
    const response = await this.doCreatePermissionGrant({
      userSelector,
      permissions,
      authContext: requireNotUndefinedOrNull(globalAuthContext),
    })
    await this.refreshAll()
    return response
  }

  modifyPermissionGrant = async (
    id: PermissionGrantId,
    userSelector: EntitySelector,
    permissions: readonly Permission[]
  ): Promise<PermissionGrantDTO> => {
    const response = await this.doModifyPermissionGrant({
      id,
      userSelector,
      permissions,
      authContext: requireNotUndefinedOrNull(globalAuthContext),
    })
    await this.refreshAll()
    return response
  }

  deletePermissionGrant = async (id: PermissionGrantId): Promise<void> => {
    await this.doDeletePermissionGrant(id)
    await this.refreshAll()
  }

  private static fetchPermissionGrantDefault = (id: PermissionGrantId) =>
    getPermissionGrant({ id, authContext: requireNotUndefinedOrNull(globalAuthContext) })

  private static fetchPermissionGrantsPageDefault = (pageIndex: number, pageSize: number) =>
    getPermissionGrants({ pageIndex, pageSize, authContext: requireNotUndefinedOrNull(globalAuthContext) }).then(
      (response) => ({
        entries: new EqualityHashMap(
          response.documents
            .asJsReadonlyArrayView()
            .map((it): [PermissionGrantId, PermissionGrantDTO] => [it.grantId, it])
        ),
        totalCount: response.totalCount,
      })
    )

  private static deletePermissionGrantDefault = async (id: PermissionGrantId) =>
    deletePermissionGrant({ id, authContext: requireNotUndefinedOrNull(globalAuthContext) })

  private static createPermissionGrantDefault = async (
    params: CreatePermissionGrantParameters
  ): Promise<PermissionGrantDTO> => createPermissionGrant(params)

  private static modifyPermissionGrantDefault = async (
    params: UpdatePermissionGrantParameters
  ): Promise<PermissionGrantDTO> => updatePermissionGrant(params)
}

export default PermissionGrantRepository
